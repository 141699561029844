import React, {useEffect, useState} from 'react';
import moment from "moment";

import {getUser} from "../auth/Auth";
import {loadSubAccountOrders} from "../common/Common";

import Pagination from "../components/pagination/Pagination";

import moreicon from "../assests/images/more.png";
import Searchicon from "../assests/images/searchtxtboxicon.png";
import BlueIcon from "../assests/images/bluedot.png";
import notificationping from "../assests/images/notificationping.png";

import '../assests/css/Orderhistory.css';
import '../assests/css/Resellermanager.css';

function ResellerOrder() {
    const [user, setUser] = useState(null);
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadSubAccountOrders(data.api_token).then(data => {
                setOrders(data);
                setFilteredOrders(data);
            }).catch(() => null);
        }).catch(() => null);
    }, []);

    const orderBody = (orders) => {
        let body = [];
        orders.forEach((order, index) => {
            body.push(
                <tr key={index}>
                    <td>
                        <span style={{
                            fontWeight: "400",
                            fontSize: 14,
                            color: "#007BFF",
                            lineHeight: "20px"
                        }}>{order.reference}</span>
                    </td>
                    <td className="carrier-column tab-column">
                        <img src={order.product.image_url} alt={order.product.name}
                             className='Verizon_Logo order-logo'/>
                        <span>{order.product.name}</span>
                    </td>
                    <td style={{paddingTop: "1%"}}>
                        <strong>{order.user.email}</strong><br/>
                        ({order.user.role.name})<br/>
                        ({user.role_id === 1 && order.user.role.name === "SUB-RESELLER" ? order.user.created_by.created_by.email : order.user.created_by.email})
                    </td>
                    <td className="desktop-column">${order.price}x{order.quantity} = ${order.quantity * order.price}</td>
                    <td className="desktop-column">
                        ${(order.quantity * order.price) - (user.role_id === 1 && order.user.role.name === "SUB-RESELLER" ? (order.quantity * order.parent_parent_parent_price) : (user.role_id === 1 && order.user.role.name === "RESELLER") || (user.role_id === 2 && order.user.role.name === "SUB-RESELLER") ? (order.quantity * order.parent_parent_price) : (order.quantity * order.parent_price))}
                    </td>
                    <td className="status-column tab-column">
                        {order.status === 'Completed' ?
                            <img src={notificationping} alt="GreenIcon" className='BlueIcon'/>
                            : <img src={BlueIcon} alt="BlueIcon" className='BlueIcon'/>
                        }
                        <span>{order.status}</span>
                    </td>
                    <td className="desktop-column">{moment(new Date(order.created_at)).format('MMM Do YYYY, h:mm:ss a')}</td>
                    <td>
                        <button className='More_Btn' data-bs-toggle="modal" data-bs-target="#resellerOrderResultModal"
                                onClick={() => setSelectedOrder(order)}>
                            <img src={moreicon} alt="moreicon" className='moreicon'/>
                        </button>
                    </td>
                </tr>
            )
        });
        return body;
    }

    const filterOrder = (text) => {
        let filteredOrders;
        if (text === '') {
            filteredOrders = orders;
        } else {
            text = text.toLowerCase();
            filteredOrders = orders.filter((order) => `${order.reference}`.toLowerCase().includes(text) || `${order.product.name}`.toLowerCase().includes(text) || `${order.user.email}`.toLowerCase().includes(text));
        }
        setFilteredOrders(filteredOrders);
    };

    const orderResultBody = () => {
        let body = [];
        selectedOrder.order_numbers.forEach((orderNumber, index) => {
            body.push(
                <p key={index}>
                    [Phone #: <b>{orderNumber.number.number}</b>, Account #: <b>{orderNumber.number.account}</b>,
                    Pin: <b>{orderNumber.number.pin}</b>]
                </p>
            )
        });
        return body;
    }

    if (user) {
        const pageSize = 10,
            firstPageIndex = (currentPage - 1) * pageSize,
            lastPageIndex = firstPageIndex + pageSize,
            currentPageOrders = filteredOrders.slice(firstPageIndex, lastPageIndex);
        return (
            <div>
                <p className='ManMyUser'>
                    {user.role_id === 1 ? 'Seller' : user.role_id === 2 ? 'Reseller' : 'Sub-Reseller'} Order History
                </p>
                <div style={{display: "flex"}}>
                    <div className="Search_Bbox" style={{width: '100%', marginRight: 0}}>
                        <img src={Searchicon} alt="Searchicon" className='Search_Icon'/>
                        <input type="label" placeholder='Type to Search' className='Search_Txt'
                               onChange={(event) => filterOrder(event.target.value)}/>
                    </div>
                </div>
                <div>
                    <table className='Tableee'>
                        <thead className='Theaddd'>
                        <tr>
                            <th>Order ID</th>
                            <th className="tab-column">Carrier</th>
                            <th>Email</th>
                            <th className="desktop-column">Quantity/Cost</th>
                            <th className="desktop-column">Commission</th>
                            <th className="tab-column">Status</th>
                            <th className="column-w-20 desktop-column">Date</th>
                            <th>MORE</th>
                        </tr>
                        </thead>
                        <tbody className='Tbodyyy'>
                        {orderBody(currentPageOrders)}
                        </tbody>
                    </table>
                    <div className="status-detail-container">
                        <div>
                            <img src={notificationping} alt="GreenIcon" className='BlueIcon'/>
                            Completed
                        </div>
                        <div>
                            <img src={BlueIcon} alt="BlueIcon" className='BlueIcon'/>
                            Pending
                        </div>
                    </div>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={filteredOrders.length}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
                <div className="modal fade" id="resellerOrderResultModal" tabIndex="-1"
                     aria-labelledby="resellerOrderResultModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog" style={{maxWidth: 'fit-content'}}>
                        {selectedOrder &&
                        <div className="modal-content" style={{width: '30vw'}}>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="resellerOrderResultModalLabel">
                                    <b>{selectedOrder.reference}</b> - Order Result
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                {orderResultBody()}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default ResellerOrder;
