import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Select from "react-select";
import {toast} from 'react-toastify';

import {createUser, getUser} from "../auth/Auth";
import {loadProducts, loadUsers} from "../common/Common";
import {deleteRequest, postRequest} from "../routes/Routes";

import Pagination from "../components/pagination/Pagination";

import Searchicon from "../assests/images/searchtxtboxicon.png";

import '../assests/css/Orderhistory.css';
import '../assests/css/Resellermanager.css';

function ResellerManager() {
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [success, setSuccess] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserDefault, setSelectedUserDefault] = useState(null);
    const [edit, setEdit] = useState(false);
    const [editBalance, setEditBalance] = useState(false);
    const [products, setProducts] = useState([]);
    const [invoice, setInvoice] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            if (data.role_id === 1) {
                setRoles([
                    {id: 1, name: 'ADMIN'},
                    {id: 2, name: 'SELLER'}
                ]);
            } else if (data.role_id === 2) {
                setRoles([
                    {id: 3, name: 'RESELLER'}
                ]);
            } else if (data.role_id === 3) {
                setRoles([
                    {id: 4, name: 'SUB-RESELLER'}
                ]);
            }
            loadUsers(data.api_token).then(data => {
                setUsers(data);
                setFilteredUsers(data);
            }).catch(() => null);
        }).catch(() => null);
        loadProducts().then(data => {
            setProducts(data);
        }).catch(() => null);
    }, []);

    const userBody = (users) => {
        let body = [];
        users.forEach((user, index) => {
            body.push(
                <tr key={index}>
                    <td><span style={{
                        fontWeight: "400",
                        fontSize: 14,
                        color: "#007BFF",
                        lineHeight: "20px"
                    }}>{user.email}</span></td>
                    <td>${user.balance}</td>
                    <td>{user.name}</td>
                    <td className="desktop-column"><span style={{
                        fontWeight: "400",
                        fontSize: 14,
                        color: "#007BFF",
                        lineHeight: "20px"
                    }}>{user.created_by.email}</span></td>
                    <td className="tab-column">{user.role.name}</td>
                    <td className="desktop-column">{moment(new Date(user.created_at)).format('MMM Do YYYY, h:mm:ss a')}</td>
                    <td>
                        <button className='Manage_Btn' data-bs-toggle="modal" data-bs-target="#manageUserModal"
                                onClick={() => {
                                    setSelectedUser(user);
                                    setSelectedUserDefault(JSON.stringify(user));
                                    setEdit(false);
                                    setEditBalance(false);
                                }}>
                            Manage
                        </button>
                    </td>
                </tr>
            );
        });
        return body;
    }

    const handleChange = (event, key) => {
        setSelectedUser(values => ({
            ...values,
            [key]: key === 'role' ? event : key === 'online' ? event.target.checked : event.target.value
        }));
    }

    const formSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        if (!edit && event.target.role.value === '') {
            setError(values => ({...values, error: true, message: "Select a role!"}));
            setSuccess(values => ({...values, success: false, message: null}));
            setLoading(false);
            return;
        }
        let values;
        if (edit) {
            values = {
                name: selectedUser.name,
                email: selectedUser.email,
                role: typeof selectedUser.role === "object" ? selectedUser.role.id : selectedUser.role_id,
                balance: selectedUser.balance,
                online: selectedUser.online === 1 ? 'true' : selectedUser.online === 0 ? 'false' : String(selectedUser.online),
                products: selectedUser.products,
                endpoint: '/api/users/update/' + selectedUser.id,
                token: user.api_token
            };
        } else {
            values = {
                name: event.target.name.value,
                email: event.target.email.value,
                password: event.target.password.value,
                role: event.target.role.value,
                online: 'true',
                created_by: user.id,
                endpoint: '/api/users',
                token: user.api_token
            };
        }
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setUsers(values =>
                        values.map(user => {
                            if (user.id === selectedUser.id) {
                                return JSON.parse(selectedUserDefault);
                            }
                            return user;
                        }),
                    );
                    setFilteredUsers(values =>
                        values.map(user => {
                            if (user.id === selectedUser.id) {
                                return JSON.parse(selectedUserDefault);
                            }
                            return user;
                        }),
                    );
                    setSelectedUser(JSON.parse(selectedUserDefault));
                    setError(values => ({...values, error: true, message: response.data.message}));
                } else {
                    if (edit) {
                        setUsers(values =>
                            values.map(user => {
                                if (user.id === selectedUser.id) {
                                    return {
                                        ...response.data.user,
                                        role: response.data.role,
                                        created_by: response.data.createdBy
                                    };
                                }
                                return user;
                            }),
                        );
                        setFilteredUsers(values =>
                            values.map(user => {
                                if (user.id === selectedUser.id) {
                                    return {
                                        ...response.data.user,
                                        role: response.data.role,
                                        created_by: response.data.createdBy
                                    };
                                }
                                return user;
                            }),
                        );
                        setSelectedUser({
                            ...response.data.user,
                            role: response.data.role,
                            created_by: response.data.createdBy
                        });
                        setSelectedUserDefault(JSON.stringify({
                            ...response.data.user,
                            role: response.data.role,
                            created_by: response.data.createdBy
                        }));
                        if (selectedUser.id === user.id) {
                            createUser(response.data.user).then(() => {
                                window.location.href = '/';
                            });
                        }
                    } else {
                        setUsers(values => [...values, {
                            ...response.data.user,
                            role: response.data.role,
                            created_by: response.data.createdBy
                        }]);
                        setFilteredUsers(values => [...values, {
                            ...response.data.user,
                            role: response.data.role,
                            created_by: response.data.createdBy
                        }]);
                    }
                    setError(values => ({...values, error: false, message: null}));
                    setSuccess(values => ({...values, success: true, message: response.data.message}));
                    loadUsers(user.api_token).then(data => {
                        setUsers(data);
                        setFilteredUsers(data);
                    }).catch(() => null);
                }
            } else {
                setUsers(values =>
                    values.map(user => {
                        if (user.id === selectedUser.id) {
                            return JSON.parse(selectedUserDefault);
                        }
                        return user;
                    }),
                );
                setFilteredUsers(values =>
                    values.map(user => {
                        if (user.id === selectedUser.id) {
                            return JSON.parse(selectedUserDefault);
                        }
                        return user;
                    }),
                );
                setSelectedUser(JSON.parse(selectedUserDefault));
                setError(values => ({...values, error: true, message: "An error occurred!"}));
            }
            setEdit(false);
            setLoading(false);
        });
    }

    const deleteUser = () => {
        deleteRequest('/api/users/' + selectedUser.id, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    setUsers(values =>
                        values.filter(function (user) {
                            return user.id !== selectedUser.id;
                        }),
                    );
                    setFilteredUsers(values =>
                        values.filter(function (user) {
                            return user.id !== selectedUser.id;
                        }),
                    );
                    toast.success('Successfully deleted User', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setEdit(false);
        });
    }

    const filterUsers = (text) => {
        let filteredUsers;
        if (text === '') {
            filteredUsers = users;
        } else {
            text = text.toLowerCase();
            filteredUsers = users.filter((user) => `${user.name}`.toLowerCase().includes(text) || `${user.email}`.toLowerCase().includes(text) || `${user.created_by.email}`.toLowerCase().includes(text));
        }
        setFilteredUsers(filteredUsers);
    };

    const productUserBody = () => {
        let body = [];
        products.forEach((product, index) => {
            let productUser = selectedUser.products.filter((productUser) => productUser.product_id === product.id);
            body.push(
                <p key={index} style={{fontSize: 15}}>
                    {product.name} Custom
                    Number: <b>${productUser.length > 0 ? productUser[0].price : product.price}</b><br/>
                </p>
            );
        });
        return body;
    }

    const productUserInputBody = () => {
        let body = [];
        products.forEach((product, index) => {
            let productUser = selectedUser.products.filter((productUser) => productUser.product_id === product.id),
                currentUserProduct = user.products.filter((productUser) => productUser.product_id === product.id);
            body.push(
                <div className="form-group" key={"priceInput" + index}>
                    <label htmlFor={"priceInput" + index} className="form-label">{product.name} Custom Number</label>
                    <input className="form-control" type="number" step=".01"
                           placeholder={product.name + ' Custom Number'}
                           id={"priceInput" + index}
                           required
                           min={currentUserProduct.length > 0 ? currentUserProduct[0].price : product.price}
                           value={productUser.length > 0 ? productUser[0].price : product.price}
                           onChange={(event) => handlePriceChange(event, product.id, productUser)}/>
                </div>
            );
        });
        return body;
    }

    const handlePriceChange = (event, product_id, productUser) => {
        if (productUser.length > 0) {
            let index = selectedUser.products.findIndex(e => e.product_id === product_id);
            selectedUser.products[index].price = event.target.value;
        } else {
            selectedUser.products.push({
                product_id: product_id,
                user_id: selectedUser.id,
                price: event.target.value,
            });
        }
        setSelectedUser(values => ({
            ...values,
            products: selectedUser.products
        }));
    }

    const handleBalanceChange = (event, key) => {
        if (key === 'direction') {
            if (event.value === 'Inbound') {
                setInvoice(values => ({
                    ...values,
                    direction: event.value,
                    from_user_id: selectedUser.id,
                    to_user_id: user.id,
                    method: 'Transfer'
                }));
            } else {
                setInvoice(values => ({
                    ...values,
                    direction: event.value,
                    from_user_id: user.id,
                    to_user_id: selectedUser.id,
                    method: 'Transfer'
                }));
            }
        } else {
            setInvoice(values => ({...values, [key]: parseFloat(event.target.value)}));
        }
    }

    const invoiceFormSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        if (!invoice.hasOwnProperty('direction') || invoice.direction === '') {
            setError(values => ({...values, error: true, message: "Please select a direction!"}));
            setSuccess(values => ({...values, success: false, message: null}));
            setLoading(false);
            return;
        }
        let values;
        values = {
            ...invoice,
            generated_by: user.id,
            endpoint: '/api/invoices',
            token: user.api_token
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, message: response.data.message}));
                } else {
                    if (invoice.direction === 'Inbound') {
                        setUser(response.data.toUser);
                        createUser(response.data.toUser).then(() => {
                            window.location.href = '/';
                        });
                    } else {
                        setUser(response.data.fromUser);
                        createUser(response.data.fromUser).then(() => {
                            window.location.href = '/';
                        });
                    }
                    setError(values => ({...values, error: false, message: null}));
                    setSuccess(values => ({...values, success: true, message: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, message: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    const pageSize = 10,
        firstPageIndex = (currentPage - 1) * pageSize,
        lastPageIndex = firstPageIndex + pageSize,
        currentPageUsers = filteredUsers.slice(firstPageIndex, lastPageIndex);
    return (
        <div>
            <p className='ManMyUser'>Manage my users</p>
            <div className="search-component">
                <div className="Search_Bbox">
                    <img src={Searchicon} alt="Searchicon" className='Search_Icon'/>
                    <input type="label" placeholder='Type to Search' className='Search_Txt'
                           onChange={(event) => filterUsers(event.target.value)}/>
                </div>
                <button className='blue-button Customexport_Btn' data-bs-toggle="modal"
                        data-bs-target="#createUserModal" onClick={() => setEdit(false)}>Add New user
                </button>
            </div>
            <div>
                <table className='Tableee'>
                    <thead className='Theaddd'>
                    <tr>
                        <th>Email</th>
                        <th>Balance</th>
                        <th>Name</th>
                        <th className="desktop-column">Parent Email</th>
                        <th className="tab-column" style={{minWidth: '3vw'}}>Role</th>
                        <th className="desktop-column">Join Date</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody className='Tbodyyy'>
                    {userBody(currentPageUsers)}
                    </tbody>
                </table>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={filteredUsers.length}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
            <div className="modal fade" id="createUserModal" tabIndex="-1" aria-labelledby="createUserModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="createUserModalLabel">Create New User</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={formSubmit} className="portal-form">
                                <div className="form-group">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input className="form-control" type="text" placeholder='Name' id="name" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input className="form-control" type="email" placeholder='Email' id="email"
                                           required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input className="form-control" type="password" placeholder='Password' id="password"
                                           required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="role" className="form-label">Role</label>
                                    <Select className="basic-single btn w-100 select" placeholder="Role"
                                            name="role" id="role" options={roles}
                                            getOptionValue={option => option['id']}
                                            getOptionLabel={option => option['name']} required/>
                                </div>
                                {success.success &&
                                <div className="alert alert-success" role="alert">
                                    {success.message}
                                </div>}
                                {error.error &&
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>}
                                <button type='submit' className="btn blue-button form-button">
                                    {loading ?
                                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    Save
                                </button>
                                <button type='button' className="btn blue-border-button form-button"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setSuccess({});
                                            setError({});
                                        }}>
                                    Cancel
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="manageUserModal" tabIndex="-1" aria-labelledby="manageUserModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="manageUserModalLabel">Manage User</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        {selectedUser ?
                            edit ?
                                <div className="modal-body">
                                    <form onSubmit={formSubmit} className="portal-form">
                                        <div className="form-group">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input className="form-control" type="text" placeholder='Name' id="name"
                                                   required
                                                   value={selectedUser.name}
                                                   onChange={(event) => handleChange(event, 'name')}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input className="form-control" type="email" placeholder='Email' id="email"
                                                   required
                                                   value={selectedUser.email}
                                                   onChange={(event) => handleChange(event, 'email')}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="role" className="form-label">Role</label>
                                            <Select className="basic-single btn w-100 select" placeholder="Role"
                                                    name="role" id="role" options={roles}
                                                    defaultValue={typeof selectedUser.role === "object" ? selectedUser.role : roles.find((role) => {
                                                        return role.id === Number(selectedUser.role_id);
                                                    })}
                                                    getOptionValue={option => option['id']}
                                                    getOptionLabel={option => option['name']} required
                                                    onChange={(event) => handleChange(event, 'role')}/>
                                        </div>
                                        {selectedUser.role_id === 1 &&
                                        <div className="form-group">
                                            <label htmlFor="balance" className="form-label">Balance</label>
                                            <input className="form-control" type="number"
                                                   placeholder='Balance'
                                                   id="balance"
                                                   min="0"
                                                   step=".01"
                                                   required
                                                   disabled
                                                   value={selectedUser.balance}
                                                   onChange={(event) => handleChange(event, 'balance')}/>
                                        </div>
                                        }
                                        {selectedUser.role_id !== 1 && [
                                            <div className="form-group" key="userOnline">
                                                <label htmlFor="online" className="form-label">Online</label>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch"
                                                           id="online" checked={selectedUser.online}
                                                           onChange={(event) => handleChange(event, 'online')}/>
                                                </div>
                                            </div>,
                                            <h5 key="itemPricingHeading">Item Pricing</h5>,
                                            productUserInputBody(),
                                        ]}
                                        {success.success &&
                                        <div className="alert alert-success" role="alert">
                                            {success.message}
                                        </div>}
                                        {error.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {error.message}
                                        </div>}
                                        <button type='submit' className="btn blue-button form-button">
                                            {loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Save
                                        </button>
                                        <button type='button' className="btn blue-border-button form-button"
                                                onClick={() => {
                                                    setUsers(values =>
                                                        values.map(user => {
                                                            if (user.id === selectedUser.id) {
                                                                return JSON.parse(selectedUserDefault);
                                                            }
                                                            return user;
                                                        }),
                                                    );
                                                    setFilteredUsers(values =>
                                                        values.map(user => {
                                                            if (user.id === selectedUser.id) {
                                                                return JSON.parse(selectedUserDefault);
                                                            }
                                                            return user;
                                                        }),
                                                    );
                                                    setSelectedUser(JSON.parse(selectedUserDefault));
                                                    setEdit(false);
                                                    setSuccess({});
                                                    setError({});
                                                }}>
                                            Cancel
                                        </button>
                                    </form>
                                </div>
                                : editBalance ?
                                <div className="modal-body">
                                    <form onSubmit={invoiceFormSubmit} className="portal-form">
                                        <div className="form-group">
                                            <label htmlFor="direction" className="form-label">Direction</label>
                                            <Select className="basic-single btn w-100 select" placeholder="Direction"
                                                    name="direction" id="direction"
                                                    options={[
                                                        {
                                                            value: 'Inbound',
                                                            label: 'Transfer from this user balance to mine'
                                                        },
                                                        {
                                                            value: 'Outbound',
                                                            label: 'Transfer from my balance to this user'
                                                        }
                                                    ]}
                                                    required
                                                    onChange={(event) => handleBalanceChange(event, 'direction')}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="amount" className="form-label">Amount</label>
                                            <input className="form-control" type="number"
                                                   placeholder='Amount'
                                                   id="amount"
                                                   min="0"
                                                   step=".01"
                                                   required
                                                   onChange={(event) => handleBalanceChange(event, 'amount')}/>
                                        </div>
                                        {success.success &&
                                        <div className="alert alert-success" role="alert">
                                            {success.message}
                                        </div>}
                                        {error.error &&
                                        <div className="alert alert-danger" role="alert">
                                            {error.message}
                                        </div>}
                                        <button type='submit' className="btn blue-button form-button">
                                            {loading ?
                                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                      aria-hidden="true"/>
                                                : null
                                            }
                                            Save
                                        </button>
                                        <button type='button' className="btn blue-border-button form-button"
                                                onClick={() => {
                                                    setEditBalance(false);
                                                    setSuccess({});
                                                    setError({});
                                                }}>
                                            Cancel
                                        </button>
                                    </form>
                                </div>
                                : <div className="modal-body">
                                    Name: <b>{selectedUser.name}</b><br/>
                                    Balance: <b>${selectedUser.balance}</b>
                                    {selectedUser.role_id !== 1 ? [
                                        <hr key="hr"/>,
                                        <h5 key="itemPricingHeading">Item Pricing</h5>,
                                        productUserBody(),
                                        <br key="br"/>,
                                    ] : [<br key="br1"/>, <br key="br2"/>]
                                    }
                                    <button className="btn blue-button form-button" onClick={() => setEdit(true)}>
                                        Edit User
                                    </button>
                                    {selectedUser.role_id !== 1 &&
                                    <button className="btn green-button form-button"
                                            onClick={() => setEditBalance(true)}>
                                        Update Balance
                                    </button>
                                    }
                                    <button className="btn red-button form-button" data-bs-dismiss="modal"
                                            onClick={() => deleteUser()}>
                                        Delete User
                                    </button>
                                    <button className="btn blue-border-button form-button" data-bs-dismiss="modal">
                                        Cancel
                                    </button>
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResellerManager;
