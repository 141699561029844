import React, {useEffect, useState} from 'react';
import moment from "moment";
import {CSVLink} from "react-csv";

import {getUser} from "../auth/Auth";
import {loadOrders} from "../common/Common";

import Pagination from "../components/pagination/Pagination";

import BlueIcon from "../assests/images/bluedot.png";
import Searchicon from "../assests/images/searchtxtboxicon.png";
import moreicon from "../assests/images/more.png";
import notificationping from "../assests/images/notificationping.png";

import '../assests/css/Orderhistory.css';

function Orderhistory() {
    const [user, setUser] = useState(null);
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [exportButtons, setExportButtons] = useState([
        'Phone Number',
        'Account Number',
        'PIN',
        'Carrier',
        'Order ID',
        'Area Code',
        'Quantity',
        'Cost',
        'Date'
    ]);
    const [selectedExportButtons, setSelectedExportButtons] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [error, setError] = useState({});

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadOrders(data.api_token).then(data => {
                setOrders(data);
                setFilteredOrders(data);
            }).catch(() => null);
        }).catch(() => null);
    }, []);

    const orderBody = (orders) => {
        let body = [];
        orders.forEach((order, index) => {
            body.push(
                <tr key={index}>
                    <td>
                        <input type="checkbox" className='Carrier_Btn'
                               onChange={(event) => onCheckboxChange(event, order)}/>
                        <span style={{
                            fontWeight: "400",
                            fontSize: 14,
                            color: "#007BFF",
                            lineHeight: "20px"
                        }}>{order.reference}</span>
                    </td>
                    <td className="carrier-column">
                        <img src={order.product.image_url} alt={order.product.name}
                             className='Verizon_Logo order-logo'/>
                        <span>{order.product.name}</span>
                    </td>
                    <td className="desktop-column">{moment(new Date(order.created_at)).format('MMM Do YYYY, h:mm:ss a')}</td>
                    <td className="desktop-column">{order.area_code.area_code}</td>
                    <td className="tab-column">x{order.quantity}</td>
                    <td>${order.quantity * order.price}</td>
                    <td className="status-column">
                        {order.status === 'Completed' ?
                            <img src={notificationping} alt="GreenIcon" className='BlueIcon'/>
                            : <img src={BlueIcon} alt="BlueIcon" className='BlueIcon'/>
                        }
                        <span>{order.status}</span>
                    </td>
                    <td>
                        <button className='More_Btn' data-bs-toggle="modal" data-bs-target="#orderResultModal"
                                onClick={() => setSelectedOrder(order)}>
                            <img src={moreicon} alt="moreicon" className='moreicon'/>
                        </button>
                    </td>
                </tr>
            )
        });
        return body;
    }

    const filterOrder = (text) => {
        let filteredOrders;
        if (text === '') {
            filteredOrders = orders;
        } else {
            text = text.toLowerCase();
            filteredOrders = orders.filter((order) => `${order.reference}`.toLowerCase().includes(text) || `${order.product.name}`.toLowerCase().includes(text));
        }
        setFilteredOrders(filteredOrders);
    };

    const orderResultBody = () => {
        let body = [];
        selectedOrder.order_numbers.forEach((orderNumber, index) => {
            body.push(
                <p key={index}>
                    [Phone #: <b>{orderNumber.number.number}</b>, Account #: <b>{orderNumber.number.account}</b>,
                    Pin: <b>{orderNumber.number.pin}</b>]
                </p>
            )
        });
        return body;
    }

    const onCheckboxChange = (event, order) => {
        if (event.target.checked) {
            setSelectedOrders(values => [...values, order]);
        } else {
            setSelectedOrders(values =>
                values.filter(function (filteredOrders) {
                    return filteredOrders.id !== order.id;
                }),
            );
        }
    }

    const customExportBody = (exportButtons, selectedExportButtons) => {
        let body = [];
        body.push(
            <p key="selectedParagraph">Select which fields you would like to export</p>
        );
        if (selectedExportButtons.length > 0) {
            selectedExportButtons.forEach((button, index) => {
                body.push(
                    <button key={"selectedButton" + index} className="btn blue-button export-button"
                            onClick={() => handleExportButton(button)}>
                        {button} &emsp; X
                    </button>
                );
            });
        } else {
            body.push(
                <p style={{opacity: 0.5}}>No fields selected</p>
            );
        }
        body.push(
            <p key="unselectedParagraph">Unselected</p>
        );
        body.push(exportButtons.filter(exportButton => !selectedExportButtons.includes(exportButton)).map((button, index) => {
            return <button key={"selectedButton" + index} className="btn blue-button export-button"
                           onClick={() => handleExportButton(button)}>
                {button}
            </button>
        }));
        return body;
    }

    const handleExportButton = (button) => {
        if (selectedExportButtons.includes(button)) {
            setSelectedExportButtons(values =>
                values.filter(function (filteredButton) {
                    return filteredButton !== button;
                }),
            );
        } else {
            setSelectedExportButtons(values => [...values, button]);
        }
    }

    const downloadSelectedOrdersReport = (event, done) => {
        if (selectedExportButtons.length === 0) {
            setError(values => ({...values, error: true, message: "Please select the required fields!"}));
            done(false);
        } else {
            let sortArray = ['Order ID', "Carrier", 'Area Code', 'Quantity', 'Cost', 'Date', 'Phone Number', 'Account Number', 'PIN'],
                sortedExportButtons = selectedExportButtons.sort((a, b) => sortArray.indexOf(a) - sortArray.indexOf(b)),
                data = [sortedExportButtons];
            selectedOrders.forEach((order) => {
                if (sortedExportButtons.some(item => ['Phone Number', 'Account Number', 'PIN'].includes(item))) {
                    order.order_numbers.forEach((orderNumber) => {
                        let orderData = [];
                        sortedExportButtons.forEach((button) => {
                            if (button === 'Carrier') {
                                orderData.push(order.product.name);
                            } else if (button === 'Order ID') {
                                orderData.push(order.reference);
                            } else if (button === 'Area Code') {
                                orderData.push(order.area_code.area_code);
                            } else if (button === 'Quantity') {
                                orderData.push(order.quantity);
                            } else if (button === 'Cost') {
                                orderData.push(order.quantity * order.price);
                            } else if (button === 'Date') {
                                orderData.push(moment(new Date(order.created_at)).format('MMM Do YYYY'));
                            } else if (button === 'Phone Number') {
                                orderData.push(orderNumber.number.number);
                            } else if (button === 'Account Number') {
                                orderData.push(orderNumber.number.account);
                            } else if (button === 'PIN') {
                                orderData.push(orderNumber.number.pin);
                            }
                        });
                        data.push(orderData);
                    });
                } else {
                    let orderData = [];
                    sortedExportButtons.forEach((button) => {
                        if (button === 'Carrier') {
                            orderData.push(order.product.name);
                        } else if (button === 'Order ID') {
                            orderData.push(order.reference);
                        } else if (button === 'Area Code') {
                            orderData.push(order.area_code.area_code);
                        } else if (button === 'Quantity') {
                            orderData.push(order.quantity);
                        } else if (button === 'Cost') {
                            orderData.push(order.quantity * order.price);
                        } else if (button === 'Date') {
                            orderData.push(moment(new Date(order.created_at)).format('MMM Do YYYY'));
                        }
                    });
                    data.push(orderData);
                }
            });
            setCsvData(data);
            setError(values => ({...values, error: false, message: null}));
            done(true);
        }
    }

    if (user) {
        const pageSize = 10,
            firstPageIndex = (currentPage - 1) * pageSize,
            lastPageIndex = firstPageIndex + pageSize,
            currentPageOrders = filteredOrders.slice(firstPageIndex, lastPageIndex);
        return (
            <div>
                <div className="search-component">
                    <div className="Search_Bbox">
                        <img src={Searchicon} alt="Searchicon" className='Search_Icon'/>
                        <input type="label" placeholder='Type to Search' className='Search_Txt'
                               onChange={(event) => filterOrder(event.target.value)}/>
                    </div>
                    <button className='blue-button Customexport_Btn' disabled={selectedOrders.length === 0}
                            data-bs-toggle="modal" data-bs-target="#customExportModal">
                        Custom Export
                    </button>
                </div>
                <div>
                    <table className='Tableee'>
                        <thead className='Theaddd'>
                        <tr>
                            <th>ORDER</th>
                            <th>CARRIER</th>
                            <th className="column-w-20 desktop-column">DATE</th>
                            <th className="desktop-column">AREA CODE</th>
                            <th className="tab-column">QUANTITY</th>
                            <th>COST</th>
                            <th>STATUS</th>
                            <th>MORE</th>
                        </tr>
                        </thead>
                        <tbody className='Tbodyyy'>
                        {orderBody(currentPageOrders)}
                        </tbody>
                    </table>
                    <div className="status-detail-container">
                        <div>
                            <img src={notificationping} alt="GreenIcon" className='BlueIcon'/>
                            Completed
                        </div>
                        <div>
                            <img src={BlueIcon} alt="BlueIcon" className='BlueIcon'/>
                            Pending
                        </div>
                    </div>
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={filteredOrders.length}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage(page)}
                    />
                </div>
                <div className="modal fade" id="orderResultModal" tabIndex="-1"
                     aria-labelledby="orderResultModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog" style={{maxWidth: 'fit-content'}}>
                        {selectedOrder &&
                        <div className="modal-content" style={{width: '30vw'}}>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="orderResultModalLabel">
                                    <b>{selectedOrder.reference}</b> - Order Result
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                {orderResultBody()}
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <div className="modal fade" id="customExportModal" tabIndex="-1"
                     aria-labelledby="customExportModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog">
                        {selectedOrders.length > 0 &&
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="customExportModalLabel">
                                    Export Options
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                {customExportBody(exportButtons, selectedExportButtons)}
                                <button type='button' className="btn blue-button form-button" data-bs-dismiss="modal"
                                        style={{marginTop: '5%'}}
                                        disabled={selectedExportButtons.length === 0}>
                                    <CSVLink
                                        data={csvData}
                                        asyncOnClick={true}
                                        filename={"orders.csv"}
                                        className="nav-link"
                                        onClick={downloadSelectedOrdersReport}>
                                        Download
                                    </CSVLink>
                                </button>
                                {error.error &&
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>}
                                <button type='button' className="btn blue-border-button form-button"
                                        data-bs-dismiss="modal">
                                    Cancel
                                </button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default Orderhistory;
