import React, {useEffect, useState} from 'react';
import moment from "moment";

import {getUser} from "../auth/Auth";
import {loadNotifications} from "../common/Common";
import {getRequest} from "../routes/Routes";

import notificationping from "../assests/images/notificationping.png";
import userImage from "../assests/images/user.png";

import '../assests/css/Header.css';

function Header({component}) {
    const [user, setUser] = useState(null);
    const [notifications, setNotifications] = useState(null);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadNotifications(data.api_token).then(data => {
                setNotifications(data);
            }).catch(() => null);
        }).catch(() => {
            setUser(null);
        });
    }, []);

    const notificationBody = (notifications) => {
        let body = [];
        body.push(<h4 key="refillHeading" style={{marginBottom: '5%'}}>Refill Requests</h4>);
        if (notifications.refillRequests.length > 0) {
            notifications.refillRequests.forEach((refillRequest, index) => {
                body.push(
                    <div key={'refillRequest' + index} className="row row-cols-3" style={{marginBottom: '5%'}}>
                        <div className="col">
                            {refillRequest.user.name}<br/>
                            <span style={{fontSize: 14}}>{refillRequest.user.email}</span>
                        </div>
                        <div className="col" style={{fontSize: 12}}>
                            {moment(new Date(refillRequest.created_at)).format('MMM Do YYYY, h:mm:ss a')}
                        </div>
                        <div className="col">
                            <button className="btn blue-button form-button"
                                    onClick={() => markAsRead(refillRequest.id)}>
                                Mark as Read
                            </button>
                        </div>
                    </div>
                )
            });
        } else {
            body.push(<p key="refillParagraph" style={{opacity: 0.5}}>No Refill Requests</p>);
        }
        body.push(<h4 key="orderHeading" style={{marginBottom: '5%'}}>Pending Orders</h4>);
        if (notifications.orders.length > 0) {
            notifications.orders.forEach((order, index) => {
                body.push(
                    <div key={'pendingOrder' + index} className="row row-cols-3" style={{marginBottom: '5%'}}>
                        <div className="col">
                            {order.reference}<br/>
                            <span style={{fontSize: 14}}>{order.user.email}</span>
                        </div>
                        <div className="col" style={{fontSize: 12}}>
                            <img src={order.product.image_url} alt={order.product.name}
                                 className='Verizon_Logo order-logo'/>
                            {order.product.name}<br/>
                            {moment(new Date(order.created_at)).format('MMM Do YYYY, h:mm:ss a')}
                        </div>
                        <div className="col" style={{fontSize: 12}}>
                            Area Code {order.area_code.area_code}<br/>
                            x{order.quantity}<br/>
                            ${order.quantity * order.price}
                        </div>
                    </div>
                )
            });
        } else {
            body.push(<p key="pendingOrderParagraph" style={{opacity: 0.5}}>No Pending Orders</p>);
        }
        return body;
    }

    const markAsRead = (id) => {
        getRequest('/api/refill-requests/mark-as-read/' + id, user.api_token).then(response => {
            if (response.status === 200) {
                if (!response.data.error) {
                    notifications.refillRequests = notifications.refillRequests.filter(function (refillRequest) {
                        return refillRequest.id !== id;
                    });
                    setNotifications(values => notifications);
                }
            }
        });
    }

    if (user) {
        return (
            <div className='Nav-Bar'>
                <button className="navbar-toggler vertical-nav-button" type="button"
                        data-bs-toggle="offcanvas" data-bs-target="#verticalNavbar"
                        aria-controls="verticalNavbar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                         className="bi bi-list" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                    </svg>
                </button>
                <p className='Nav-Bar-Pagetxt'>{component}</p>
                <div className="user-detail-container">
                    <div style={{width: "15%", cursor: "pointer"}} data-bs-toggle="modal"
                         data-bs-target="#notificationModal">
                        <button className='btn notificationbell'/>
                        {notifications && (notifications.refillRequests.length > 0 || notifications.orders.length > 0) &&
                        <img src={notificationping} alt="Notification-Ping" className='notificationping'/>
                        }
                    </div>
                    <div className="user-details" data-bs-toggle="modal" data-bs-target="#profileModal">
                        <img src={userImage} alt="user" className='User'/>
                        <div style={{marginLeft: "10%"}}>
                            <p className='Name'>{user.name}</p>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="profileModal" tabIndex="-1" aria-labelledby="profileModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="profileModalLabel">Profile</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input className="form-control" type="text" placeholder='Name' id="name" required
                                           disabled value={user.name}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input className="form-control" type="email" placeholder='Email' id="email"
                                           required disabled value={user.email}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" className="form-label">Role</label>
                                    <input className="form-control" type="role" placeholder='Role' id="role"
                                           required disabled value={user.role.name}/>
                                </div>
                                <button type='button' className="btn blue-border-button form-button"
                                        data-bs-dismiss="modal" style={{marginTop: '5%'}}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="notificationModal" tabIndex="-1"
                     aria-labelledby="notificationModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="notificationModalLabel">Notifications</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                {notifications && notificationBody(notifications)}
                                <button type='button' className="btn blue-border-button form-button"
                                        data-bs-dismiss="modal" style={{marginTop: '5%'}}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default Header;
