import React, {useEffect, useState} from 'react';
import {Chart} from "react-google-charts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";

import {getUser} from "../auth/Auth";
import {loadDateRangeOrderReport, loadDateRangeOrdersStatistics, loadProducts, loadStatistics} from "../common/Common";

import cardicon from "../assests/images/cardicon.png";
import cardIcon1 from "../assests/images/card-icon-1.png";
import cardIcon2 from "../assests/images/card-icon-2.png";
import cardIcon3 from "../assests/images/card-icon-3.png";
import cardIcon4 from "../assests/images/card-icon-4.png";
import Commssion from "../assests/images/commissioncardbox.png";

import '../assests/css/Resellerstats.css';

export const options = {
    title: "Number Statistics",
    curveType: "function",
    legend: {position: "top"},
};

export const options2 = {
    title: "Commission Statistics",
    curveType: "function",
    legend: {position: "top"},
};

function ResellerStats() {
    const [user, setUser] = useState(null);
    const [statistics, setStatistics] = useState({});
    const [products, setProducts] = useState([]);
    const [selectedDate, setSelectedDate] = useState({
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date()
    });
    const [numberStatistics, setNumberStatistics] = useState({});
    const [fileType, setFileType] = useState({value: 'csv', label: 'CSV'});

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadStatistics(data.api_token).then(data => {
                setStatistics(data);
            }).catch(() => null);
            loadDateRangeOrdersStatistics(data.api_token, moment(new Date(selectedDate.start)).format('YYYY-MM-DD'), moment(new Date(selectedDate.end)).format('YYYY-MM-DD')).then(data => {
                setNumberStatistics(data);
            }).catch(() => null);
        }).catch(() => null);
        loadProducts().then(data => {
            setProducts(data);
        }).catch(() => null);
    }, []);

    useEffect(() => {
        if (user) {
            loadDateRangeOrdersStatistics(user.api_token, moment(new Date(selectedDate.start)).format('YYYY-MM-DD'), moment(new Date(selectedDate.end)).format('YYYY-MM-DD')).then(data => {
                setNumberStatistics(data);
            }).catch(() => null);
        }
    }, [selectedDate]);

    const downloadDateRangeOrderReport = (selectedDate, fileType) => {
        let start = moment(new Date(selectedDate.start)).format('YYYY-MM-DD'),
            end = moment(new Date(selectedDate.end)).format('YYYY-MM-DD');
        loadDateRangeOrderReport(user.api_token, start, end, fileType.value).then(data => {
            data.arrayBuffer().then(function (buffer) {
                const url = URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement('a');
                link.href = url;
                link.download = `orders - ${start} - ${end}.${fileType.value}`;
                link.click();
                URL.revokeObjectURL(url);
            });
        }).catch(() => null);
    }

    const productBody = () => {
        let body = [];
        products.forEach((product, index) => {
            body.push(
                <div className="col" key={index}>
                    <div className='order-card stats-card'>
                        <div style={{width: "15%", textAlign: "left"}}>
                            <img src={product.image_url} alt={product.name} className='Verizon_Logo PhotoIcn'/>
                        </div>
                        <div style={{width: "85%"}}>
                            <p className='CrdName'>{product.name} Port Numbers</p>
                            <p className='Card_Numbers'>{numberStatistics[product.name]}</p>
                        </div>
                    </div>
                </div>
            )
        });
        return body;
    }

    if (user) {
        return (
            <div>
                <div className='Cd_Section stats-container'>
                    <div className='Accblnce_Card'>
                        <div style={{width: "75%"}}>
                            <p className='Acct_Balance'>Account Balance</p>
                            <p className='Price'>${user.balance}</p>
                        </div>
                        <div style={{width: "25%", textAlign: "right"}}>
                            <img src={cardicon} alt="cardin" className='Card_In'/>
                        </div>
                    </div>
                    <div className='Accblnce_Card Member_Card'>
                        <div style={{width: "75%"}}>
                            <p className='Acct_Balance'>Profit this Month</p>
                            <p className='Price'>${statistics.month_profit}</p>
                        </div>
                        <div style={{width: "25%", textAlign: "right"}}>
                            <img src={cardicon} alt="cardin" className='Card_In'/>
                        </div>
                    </div>
                </div>
                <div className="order-card-container Cd_Section seller-stats-container" style={{margin: "2% 0"}}>
                    <div className='order-card stats-card'>
                        <div style={{width: "75%"}}>
                            <p className='CrdName'>Users</p>
                            <p className='Card_Numbers'>{statistics.users}</p>
                        </div>
                        <div style={{width: "25%", textAlign: "right"}}>
                            <img src={cardIcon1} alt="Photoicon" className='Photoicon'/>
                        </div>
                    </div>
                    <div className='order-card stats-card'>
                        <div style={{width: "75%"}}>
                            <p className='CrdName'>Users Balance</p>
                            <p className='Card_Numbers'>${statistics.user_balance}</p>
                        </div>
                        <div style={{width: "25%", textAlign: "right"}}>
                            <img src={cardIcon2} alt="Photoicon" className='Photoicon'/>
                        </div>
                    </div>
                    <div className='order-card stats-card'>
                        <div style={{width: "75%"}}>
                            <p className='CrdName'>Numbers Today</p>
                            <p className='Card_Numbers'>{statistics.day_numbers}</p>
                        </div>
                        <div style={{width: "25%", textAlign: "right"}}>
                            <img src={cardIcon3} alt="Photoicon" className='Photoicon'/>
                        </div>
                    </div>
                    <div className='order-card stats-card'>
                        <div style={{width: "75%"}}>
                            <p className='CrdName'>Profit Today</p>
                            <p className='Card_Numbers'>${statistics.day_profit}</p>
                        </div>
                        <div style={{width: "25%", textAlign: "right"}}>
                            <img src={cardIcon4} alt="Photoicon" className='Photoicon'/>
                        </div>
                    </div>
                </div>
                <div className='Receno-Barr number-stats-container' style={{margin: "2% 0"}}>
                    <p className='Recent_Number'>Number Statistics</p>
                    <div className="download-order-container">
                        <DatePicker
                            selectsRange={true}
                            startDate={selectedDate.start}
                            endDate={selectedDate.end}
                            onChange={(update) => {
                                setSelectedDate(values => ({...values, start: update[0], end: update[1]}));
                            }}
                        />
                        <Select className="basic-single btn w-100 select"
                                placeholder="File Type"
                                name="fileType" id="fileType"
                                options={[
                                    {value: 'csv', label: 'CSV'},
                                    {value: 'xlsx', label: 'XLSX'},
                                ]}
                                defaultValue={fileType}
                                onChange={(event) => setFileType(values => ({
                                    ...values,
                                    value: event.value,
                                    label: event.label
                                }))}
                                required/>
                        <button className='blue-button Plcenew_Btn' style={{width: '100%', marginLeft: '2%'}}
                                onClick={() => downloadDateRangeOrderReport(selectedDate, fileType)}>
                            Download Orders
                        </button>
                    </div>
                </div>
                <div className='order-card-container Cd_Section mobile-stats-container row row-cols-3'>
                    {productBody()}
                    <div className="col">
                        <div className='order-card stats-card'>
                            <div style={{width: "15%", textAlign: "left"}}>
                                <img src={Commssion} alt="PhotoIcn" className='PhotoIcn'/>
                            </div>
                            <div style={{width: "85%"}}>
                                <p className='CrdName'>Commission</p>
                                <p className='Card_Numbers'>${numberStatistics.commission}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="graph-container">
                    <div className="Graphh">
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="404px"
                            data={numberStatistics.numberGraphData}
                            options={options}
                        />
                    </div>
                    <div className="Graphh2">
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="404px"
                            data={numberStatistics.commissionGraphData}
                            options={options2}
                        />
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default ResellerStats;

