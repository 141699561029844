import React, {useEffect, useState} from 'react';
import {Chart} from "react-google-charts";
import moment from "moment";

import {createUser, deleteUser, getUser} from "../auth/Auth";
import {postRequest} from "../routes/Routes";
import {loadLimitedOrders, loadMonthlyOrdersStatistics, loadUserDetails} from "../common/Common";

import Header from "../components/header";
import Accountbalance from "./accountbalance";
import Neworder from "./neworder";
import Orderhistory from "./orderhistory";
import ResellerManager from "./resellermanager";
import ResellerOrder from "./resellerorder";
import ResellerStats from "./resellerstats";
import ProductManager from "./productmanager";

import bluehomelogo from "../assests/images/bluehomelogo.png";
import whitehomelogo from "../assests/images/whitehomelogo.png";
import WhiteSidebarlogo from "../assests/images/whitesidebarlogo.png";
import BlueSidebarlogo from "../assests/images/bluesidebarlogo.png";
import cardicon from "../assests/images/cardicon.png";
import BlueIcon from "../assests/images/bluedot.png";
import moreicon from "../assests/images/more.png";
import notificationping from "../assests/images/notificationping.png";

import '../assests/css/Home.css';

function Home() {
    const [component, setComponent] = useState("Home");
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUser().then((data) => {
            loadUserDetails(data.api_token).then((data) => {
                createUser(data).then(() => null);
                setUser(data);
            })
        }).catch(() => {
            setUser(null);
        });
    }, []);

    const logout = () => {
        deleteUser().then(() => {
            setUser(null);
            window.location.href = '/login';
        });
    };

    const changeComponent = (component) => {
        setComponent(component);
    }

    if (user) {
        return (
            <div style={{display: "flex"}}>
                <div className="vertical-nav Sidebar offcanvas offcanvas-start" id="verticalNavbar" tabIndex="-1">
                    <p className='Logo'>
                        Logo
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"/>
                    </p>
                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                         aria-orientation="vertical">
                        <a className={component === 'Home' ? "nav-link active" : "nav-link"} id="v-pills-home-tab"
                           data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home"
                           aria-selected={component === 'Home' ? "true" : "false"}
                           data-bs-dismiss="offcanvas" data-bs-target="#verticalNavbar"
                           onClick={() => setComponent("Home")}>
                            <img src={whitehomelogo} alt="Sidebar-Image" className='Sidebar-Image inactive-image'/>
                            <img src={bluehomelogo} alt="Sidebar-Image" className='Sidebar-Image active-image'/>
                            <span className="Sidebar-Txt">Home</span>
                        </a>
                        <a className={component === 'Account Balance' ? "nav-link active" : "nav-link"}
                           id="v-pills-balance-tab" data-toggle="pill" key="balanceLink"
                           href="#v-pills-balance" role="tab" aria-controls="v-pills-balance"
                           aria-selected={component === 'Account Balance' ? "true" : "false"}
                           data-bs-dismiss="offcanvas" data-bs-target="#verticalNavbar"
                           onClick={() => setComponent("Account Balance")}>
                            <img src={WhiteSidebarlogo} alt="Sidebar-Image"
                                 className='Sidebar-Image inactive-image'/>
                            <img src={BlueSidebarlogo} alt="Sidebar-Image" className='Sidebar-Image active-image'/>
                            <span className="Sidebar-Txt">Balance</span>
                        </a>
                        {user.role_id === 1 &&
                        <a className={component === 'Product Manager' ? "nav-link active" : "nav-link"}
                           id="v-pills-product-manager-tab" data-toggle="pill"
                           key="productManagerLink" href="#v-pills-product-manager" role="tab"
                           aria-controls="v-pills-product-manager"
                           aria-selected={component === 'Product Manager' ? "true" : "false"}
                           data-bs-dismiss="offcanvas" data-bs-target="#verticalNavbar"
                           onClick={() => setComponent("Product Manager")}>
                            <img src={WhiteSidebarlogo} alt="Sidebar-Image"
                                 className='Sidebar-Image inactive-image'/>
                            <img src={BlueSidebarlogo} alt="Sidebar-Image" className='Sidebar-Image active-image'/>
                            <span className="Sidebar-Txt">Product Manager</span>
                        </a>
                        }
                        <a className={component === 'New Order' ? "nav-link active" : "nav-link"}
                           id="v-pills-new-order-tab" data-toggle="pill" key="newOrderLink" href="#v-pills-new-order"
                           role="tab" aria-controls="v-pills-new-order"
                           aria-selected={component === 'New Order' ? "true" : "false"}
                           data-bs-dismiss="offcanvas" data-bs-target="#verticalNavbar"
                           onClick={() => setComponent("New Order")}>
                            <img src={WhiteSidebarlogo} alt="Sidebar-Image"
                                 className='Sidebar-Image inactive-image'/>
                            <img src={BlueSidebarlogo} alt="Sidebar-Image" className='Sidebar-Image active-image'/>
                            <span className="Sidebar-Txt">New Order</span>
                        </a>
                        <a className={component === 'Order History' ? "nav-link active" : "nav-link"}
                           id="v-pills-order-history-tab" data-toggle="pill"
                           key="orderHistoryLink" href="#v-pills-order-history" role="tab"
                           aria-controls="v-pills-order-history"
                           aria-selected={component === 'Order History' ? "true" : "false"}
                           data-bs-dismiss="offcanvas" data-bs-target="#verticalNavbar"
                           onClick={() => setComponent("Order History")}>
                            <img src={WhiteSidebarlogo} alt="Sidebar-Image"
                                 className='Sidebar-Image inactive-image'/>
                            <img src={BlueSidebarlogo} alt="Sidebar-Image" className='Sidebar-Image active-image'/>
                            <span className="Sidebar-Txt">Order History</span>
                        </a>
                        {user.role_id !== 4 ? [
                            <a className={component === 'Seller Manager' || component === "Reseller Manager" || component === "Sub-Reseller Manager" ? "nav-link active" : "nav-link"}
                               id="v-pills-reseller-manager-tab" data-toggle="pill"
                               key="resellerManagerLink" href="#v-pills-reseller-manager" role="tab"
                               aria-controls="v-pills-reseller-manager"
                               aria-selected={component === 'Seller Manager' || component === "Reseller Manager" || component === "Sub-Reseller Manager" ? "true" : "false"}
                               data-bs-dismiss="offcanvas" data-bs-target="#verticalNavbar"
                               onClick={() => setComponent(user.role_id === 1 ? 'Seller Manager' : user.role_id === 2 ? 'Reseller Manager' : 'Sub-Reseller Manager')}>
                                <img src={WhiteSidebarlogo} alt="Sidebar-Image"
                                     className='Sidebar-Image inactive-image'/>
                                <img src={BlueSidebarlogo} alt="Sidebar-Image" className='Sidebar-Image active-image'/>
                                <span
                                    className="Sidebar-Txt">{user.role_id === 1 ? 'Seller Manager' : user.role_id === 2 ? 'Reseller Manager' : 'Sub-Reseller Manager'}</span>
                            </a>,
                            <a className={component === 'Seller Orders' || component === "Reseller Orders" || component === "Sub-Reseller Orders" ? "nav-link active" : "nav-link"}
                               id="v-pills-reseller-orders-tab" data-toggle="pill"
                               key="resellerOrderLink" href="#v-pills-reseller-orders" role="tab"
                               aria-controls="v-pills-reseller-orders"
                               aria-selected={component === 'Seller Orders' || component === "Reseller Orders" || component === "Sub-Reseller Orders" ? "nav-link active" : "nav-link"}
                               data-bs-dismiss="offcanvas" data-bs-target="#verticalNavbar"
                               onClick={() => setComponent(user.role_id === 1 ? 'Seller Orders' : user.role_id === 2 ? 'Reseller Orders' : 'Sub-Reseller Orders')}>
                                <img src={WhiteSidebarlogo} alt="Sidebar-Image"
                                     className='Sidebar-Image inactive-image'/>
                                <img src={BlueSidebarlogo} alt="Sidebar-Image" className='Sidebar-Image active-image'/>
                                <span
                                    className="Sidebar-Txt">{user.role_id === 1 ? 'Seller Orders' : user.role_id === 2 ? 'Reseller Orders' : 'Sub-Reseller Orders'}</span>
                            </a>,
                            <a className={component === 'Seller Stats' || component === "Reseller Stats" || component === "Sub-Reseller Stats" ? "nav-link active" : "nav-link"}
                               id="v-pills-reseller-stats-tab" data-toggle="pill"
                               key="resellerStatsLink" href="#v-pills-reseller-stats" role="tab"
                               aria-controls="v-pills-reseller-stats"
                               aria-selected={component === 'Seller Stats' || component === "Reseller Stats" || component === "Sub-Reseller Stats" ? "nav-link active" : "nav-link"}
                               data-bs-dismiss="offcanvas" data-bs-target="#verticalNavbar"
                               onClick={() => setComponent(user.role_id === 1 ? 'Seller Stats' : user.role_id === 2 ? 'Reseller Stats' : 'Sub-Reseller Stats')}>
                                <img src={WhiteSidebarlogo} alt="Sidebar-Image"
                                     className='Sidebar-Image inactive-image'/>
                                <img src={BlueSidebarlogo} alt="Sidebar-Image" className='Sidebar-Image active-image'/>
                                <span
                                    className="Sidebar-Txt">{user.role_id === 1 ? 'Seller Stats' : user.role_id === 2 ? 'Reseller Stats' : 'Sub-Reseller Stats'}</span>
                            </a>,
                        ] : null
                        }
                        <a className="nav-link" id="v-pills-logout-tab" data-toggle="pill"
                           href="#v-pills-logout" role="tab" aria-controls="v-pills-logout"
                           aria-selected="false" onClick={() => logout()}>
                            <img src={WhiteSidebarlogo} alt="Sidebar-Image" className='Sidebar-Image inactive-image'/>
                            <img src={BlueSidebarlogo} alt="Sidebar-Image" className='Sidebar-Image active-image'/>
                            <span className="Sidebar-Txt">Logout</span>
                        </a>
                    </div>
                </div>
                <div className="nav-detail-container">
                    <Header component={component}/>
                    <div className="tab-content portal-tab-container" id="v-pills-tabContent">
                        <div className={component === "Home" ? "tab-pane fade show active" : "tab-pane fade"}
                             id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <HomeContent changeComponent={changeComponent}/>
                        </div>
                        <div className={component === "Account Balance" ? "tab-pane fade show active" : "tab-pane fade"}
                             id="v-pills-balance" role="tabpanel" key="balance"
                             aria-labelledby="v-pills-balance-tab">
                            <Accountbalance/>
                        </div>
                        {user.role_id === 1 &&
                        <div className={component === "Product Manager" ? "tab-pane fade show active" : "tab-pane fade"}
                             id="v-pills-product-manager" role="tabpanel"
                             key="productManager" aria-labelledby="v-pills-product-manager-tab">
                            <ProductManager/>
                        </div>
                        }
                        <div className={component === "New Order" ? "tab-pane fade show active" : "tab-pane fade"}
                             id="v-pills-new-order" role="tabpanel" key="newOrder"
                             aria-labelledby="v-pills-new-order-tab">
                            <Neworder/>
                        </div>
                        <div className={component === "Order History" ? "tab-pane fade show active" : "tab-pane fade"}
                             id="v-pills-order-history" role="tabpanel" key="orderHistory"
                             aria-labelledby="v-pills-order-history-tab">
                            <Orderhistory/>
                        </div>
                        {user.role_id !== 4 ? [
                            <div
                                className={['Seller Manager', "Reseller Manager", "Sub-Reseller Manager"].includes(component) ? "tab-pane fade show active" : "tab-pane fade"}
                                id="v-pills-reseller-manager" role="tabpanel" key="resellerManager"
                                aria-labelledby="v-pills-reseller-manager-tab">
                                <ResellerManager/>
                            </div>,
                            <div
                                className={['Seller Orders', "Reseller Orders", "Sub-Reseller Orders"].includes(component) ? "tab-pane fade show active" : "tab-pane fade"}
                                id="v-pills-reseller-orders" role="tabpanel" key="resellerOrder"
                                aria-labelledby="v-pills-reseller-orders-tab">
                                <ResellerOrder/>
                            </div>,
                            <div
                                className={['Seller Stats', "Reseller Stats", "Sub-Reseller Stats"].includes(component) ? "tab-pane fade show active" : "tab-pane fade"}
                                id="v-pills-reseller-stats" role="tabpanel" key="resellerStats"
                                aria-labelledby="v-pills-reseller-stats-tab">
                                <ResellerStats/>
                            </div>,
                        ] : null
                        }
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export function HomeContent({changeComponent}) {
    const options = {
        title: "30 Day Order History",
        curveType: "function",
        legend: {position: "top"},
    };

    const [user, setUser] = useState(null);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [monthlyOrderData, setMonthlyOrderData] = useState([]);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadLimitedOrders(data.api_token, 5).then(data => {
                setOrders(data);
            }).catch(() => null);
            loadMonthlyOrdersStatistics(data.api_token).then(data => {
                setMonthlyOrderData(data.monthly_orders);
            }).catch(() => null);
        }).catch(() => null);
    }, []);

    const orderBody = () => {
        let body = [];
        orders.forEach((order, index) => {
            body.push(
                <tr key={index}>
                    <td><span style={{
                        fontWeight: "400",
                        fontSize: 14,
                        color: "#007BFF",
                        lineHeight: "20px"
                    }}>{order.reference}</span></td>
                    <td className="carrier-column">
                        <img src={order.product.image_url} alt={order.product.name}
                             className='Verizon_Logo order-logo'/>
                        <span>{order.product.name}</span>
                    </td>
                    <td className="desktop-column">{moment(new Date(order.created_at)).format('MMM Do YYYY, h:mm:ss a')}</td>
                    <td className="desktop-column">{order.area_code.area_code}</td>
                    <td className="tab-column">x{order.quantity}</td>
                    <td>${order.quantity * order.price}</td>
                    <td className="status-column">
                        {order.status === 'Completed' ?
                            <img src={notificationping} alt="GreenIcon" className='BlueIcon'/>
                            : <img src={BlueIcon} alt="BlueIcon" className='BlueIcon'/>
                        }
                        <span>{order.status}</span>
                    </td>
                    <td>
                        <button className='More_Btn' data-bs-toggle="modal" data-bs-target="#homeOrderResultModal"
                                onClick={() => setSelectedOrder(order)}>
                            <img src={moreicon} alt="moreicon" className='moreicon'/>
                        </button>
                    </td>
                </tr>
            )
        });
        return body;
    }

    const orderResultBody = () => {
        let body = [];
        selectedOrder.order_numbers.forEach((orderNumber, index) => {
            body.push(
                <p key={index}>
                    [Phone #: <b>{orderNumber.number.number}</b>, Account #: <b>{orderNumber.number.account}</b>,
                    Pin: <b>{orderNumber.number.pin}</b>]
                </p>
            )
        });
        return body;
    }

    if (user) {
        return (
            <div>
                <div className='Cd_Section'>
                    <div className='Accblnce_Card'>
                        <div style={{width: "75%"}}>
                            <p className='Acct_Balance'>Account Balance</p>
                            <p className='Price'>${user.balance}</p>
                            <button className='white-button Refill_Btn' data-bs-toggle="modal"
                                    data-bs-target="#homeRefillAccountModal">
                                {user.role_id === 1 ?
                                    'Refill Account'
                                    : 'Request Refill'
                                }
                            </button>
                        </div>
                        <div style={{width: "25%", textAlign: "right"}}>
                            <img src={cardicon} alt="cardin" className='Card_In'/>
                        </div>
                    </div>
                    <div className='Member_Card'>
                        <p className='Member_Txt'>Member Since</p>
                        <p className='Date'>
                            {moment(new Date(user.created_at)).format('MMMM')}<br/>
                            {moment(new Date(user.created_at)).format('Do, YYYY')}
                        </p>
                    </div>
                </div>

                <div className='Receno-Barr'>
                    <p className='Recent_Number'>Recent Number Orders</p>
                    <a className="nav-link blue-button Plcenew_Btn" id="v-pills-new-order-tab" data-toggle="pill"
                       key="newOrderLink" href="#v-pills-new-order" role="tab" aria-controls="v-pills-new-order"
                       aria-selected="false" onClick={() => changeComponent('New Order')}>
                        Place New Order
                    </a>
                </div>

                <div>
                    <table className='Tableee'>
                        <thead className='Theaddd'>
                        <tr>
                            <th>ORDER</th>
                            <th>CARRIER</th>
                            <th className="column-w-20 desktop-column">DATE</th>
                            <th className="desktop-column">AREA CODE</th>
                            <th className="tab-column">QUANTITY</th>
                            <th>COST</th>
                            <th>STATUS</th>
                            <th>MORE</th>
                        </tr>
                        </thead>
                        <tbody className='Tbodyyy'>
                        {orderBody()}
                        </tbody>
                    </table>
                    <div className="status-detail-container">
                        <div>
                            <img src={notificationping} alt="GreenIcon" className='BlueIcon'/>
                            Completed
                        </div>
                        <div>
                            <img src={BlueIcon} alt="BlueIcon" className='BlueIcon'/>
                            Pending
                        </div>
                    </div>
                </div>

                <div className="Graph">
                    {monthlyOrderData.length > 0 &&
                    <Chart
                        chartType="LineChart"
                        width="100%"
                        height="400px"
                        data={monthlyOrderData}
                        options={options}
                    />
                    }
                </div>

                <div className="modal fade" id="homeOrderResultModal" tabIndex="-1"
                     aria-labelledby="homeOrderResultModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog" style={{maxWidth: 'fit-content'}}>
                        {selectedOrder &&
                        <div className="modal-content" style={{width: '30vw'}}>
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="homeOrderResultModalLabel">
                                    <b>{selectedOrder.reference}</b> - Order Result
                                </h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                            </div>
                            <div className="modal-body">
                                {orderResultBody()}
                            </div>
                        </div>
                        }
                    </div>
                </div>

                <AccountBalanceModal modalId='homeRefillAccountModal'/>
            </div>
        );
    } else {
        return null;
    }
}

export default Home;

export function AccountBalanceModal(data) {
    const [user, setUser] = useState(null);
    const [success, setSuccess] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [invoice, setInvoice] = useState({});

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
        }).catch(() => null);
    }, []);

    const handleChange = (event, key) => {
        if (event.target.value !== '') {
            if (parseFloat(user.balance) > parseFloat(event.target.value)) {
                setInvoice(values => ({
                    ...values,
                    method: 'Withdraw',
                    amount: parseFloat(user.balance) - parseFloat(event.target.value),
                    to_user_id: user.id,
                    from_user_id: user.id,
                    generated_by: user.id,
                    direction: 'Outbound',
                }));
            } else {
                setInvoice(values => ({
                    ...values,
                    method: 'Refill',
                    amount: parseFloat(event.target.value) - parseFloat(user.balance),
                    to_user_id: user.id,
                    from_user_id: user.id,
                    generated_by: user.id,
                    direction: 'Inbound',
                }));
            }
        }
    }

    const formSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        if (!invoice) {
            setError(values => ({...values, error: true, message: "Please enter an amount!"}));
            setSuccess(values => ({...values, success: false, message: null}));
            setLoading(false);
            return;
        }
        let values;
        values = {
            ...invoice,
            endpoint: '/api/invoices',
            token: user.api_token
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, message: response.data.message}));
                } else {
                    setUser(response.data.toUser);
                    createUser(response.data.toUser).then(() => {
                        window.location.href = '/';
                    });
                    setError(values => ({...values, error: false, message: null}));
                    setSuccess(values => ({...values, success: true, message: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, message: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    const requestRefillFormSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        let values = {
            endpoint: '/api/refill-requests',
            token: user.api_token
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, message: response.data.message}));
                } else {
                    setError(values => ({...values, error: false, message: null}));
                    setSuccess(values => ({...values, success: true, message: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, message: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    if (user) {
        return (
            <div className="modal fade" id={data.modalId} tabIndex="-1"
                 aria-labelledby="refillAccountModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="refillAccountModalLabel">
                                {user.role_id === 1 ? 'Refill Account' : 'Request Refill'}
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        {user.role_id === 1 ?
                            <div className="modal-body">
                                <form onSubmit={formSubmit} className="portal-form">
                                    <div className="form-group">
                                        <label htmlFor="balance" className="form-label">Balance</label>
                                        <input className="form-control" type="number"
                                               placeholder='Balance'
                                               id="balance"
                                               min="0"
                                               step=".01"
                                               required
                                               defaultValue={user.balance}
                                               onChange={(event) => handleChange(event, 'balance')}/>
                                    </div>
                                    {success.success &&
                                    <div className="alert alert-success" role="alert">
                                        {success.message}
                                    </div>}
                                    {error.error &&
                                    <div className="alert alert-danger" role="alert">
                                        {error.message}
                                    </div>}
                                    <button type='submit' className="btn blue-button form-button">
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Save
                                    </button>
                                    <button type='button' className="btn blue-border-button form-button"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                setSuccess({});
                                                setError({});
                                            }}>
                                        Cancel
                                    </button>
                                </form>
                            </div>
                            : <div className="modal-body">
                                <form onSubmit={requestRefillFormSubmit} className="portal-form">
                                    <p>
                                        Request dealer to refill balance
                                    </p>
                                    {success.success &&
                                    <div className="alert alert-success" role="alert">
                                        {success.message}
                                    </div>}
                                    {error.error &&
                                    <div className="alert alert-danger" role="alert">
                                        {error.message}
                                    </div>}
                                    <button type='submit' className="btn blue-button form-button">
                                        {loading ?
                                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                                  aria-hidden="true"/>
                                            : null
                                        }
                                        Request
                                    </button>
                                    <button type='button' className="btn blue-border-button form-button"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                setSuccess({});
                                                setError({});
                                            }}>
                                        Cancel
                                    </button>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
