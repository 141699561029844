import React, {useEffect, useState} from 'react';
import Select from 'react-select';

import {createUser, getUser} from "../auth/Auth";
import {loadAreaCodes, loadNumbers, loadProducts} from "../common/Common";
import {postRequest} from "../routes/Routes";

import cardicon from "../assests/images/cardicon.png"

import '../assests/css/Neworder.css';

function Neworder() {
    const [user, setUser] = useState(null);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [success, setSuccess] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [areaCodes, setAreaCodes] = useState([]);
    const [selectedAreaCode, setSelectedAreaCode] = useState(null);
    const [orderMessage, setOrderMessage] = useState({});
    const [numbers, setNumbers] = useState([]);
    const [numberCount, setNumberCount] = useState(0);

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
        }).catch(() => null);
        loadProducts().then(data => {
            setProducts(data);
        }).catch(() => null);
        loadNumbers().then(data => {
            setNumbers(data);
        }).catch(() => null);
        loadAreaCodes().then(data => {
            setAreaCodes(data);
        }).catch(() => null);
    }, []);

    const productBody = () => {
        let body = [];
        products.forEach((product, index) => {
            let productUser = user.products.filter((productUser) => productUser.product_id === product.id);
            body.push(
                <div className="col" key={index}>
                    <div
                        className={selectedProduct && selectedProduct.id === product.id ? 'order-card selected-order-card' : 'order-card'}
                        onClick={() => {
                            setSelectedProduct(product);
                            onAreaCodeChange(selectedAreaCode, product);
                        }}>
                        {product.online ?
                            product.numbers.length > 0 ?
                                <div className='Available_Box'>
                                    <p className='Available_Txt'>Available - INSTANT</p>
                                </div>
                                : <div className='Available_Box available-later-box'>
                                    <p className='Available_Txt available-later-txt'>Available - In 1-12/24 hours</p>
                                </div>
                            : <div className='Available_Box unavailable-box'>
                                <p className='Available_Txt unavailable-txt'>Temporary Unavailable</p>
                            </div>
                        }
                        <p className='Card_Name'>{product.name}</p>
                        <p className='Order_Numbers'>${productUser.length > 0 ? productUser[0].price : product.price}/Number</p>
                    </div>
                </div>
            )
        });
        return body;
    }

    const onAreaCodeChange = (event, product) => {
        if (event) {
            let areaCodeIndex = areaCodes.findIndex(e => e.id === parseInt(event.id));
            let filteredNumbers;
            if (product) {
                filteredNumbers = areaCodes[areaCodeIndex].numbers.filter((number) => number.product_id === product.id);
            } else {
                filteredNumbers = areaCodes[areaCodeIndex].numbers.filter((number) => number.product_id === selectedProduct.id);
            }
            setNumberCount(filteredNumbers.length);
            setSelectedAreaCode(event);
        }
    }

    const formSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        setError(values => ({...values, error: false, message: null}));
        if (event.target.areaCode.value === '') {
            setError(values => ({...values, error: true, message: "Please select an area code!"}));
            setSuccess(values => ({...values, success: false, message: null}));
            setLoading(false);
            return;
        }
        let productUser = selectedProduct ? user.products.filter((productUser) => productUser.product_id === selectedProduct.id) : [],
            price = productUser.length > 0 ? productUser[0].price : selectedProduct.price,
            areaCodeIndex = areaCodes.findIndex(e => e.id === parseInt(event.target.areaCode.value));
        if (parseFloat(user.balance) < parseFloat(`${price * event.target.quantity.value}`)) {
            setError(values => ({...values, error: true, message: "Insufficient balance!"}));
            setSuccess(values => ({...values, success: false, message: null}));
            setLoading(false);
            return;
        }
        let orderMessage;
        if (event.target.quantity.value <= numberCount) {
            orderMessage = "You'll receive all " + event.target.quantity.value + " numbers INSTANTLY.";
        } else {
            orderMessage = "You'll receive " + numberCount + " numbers INSTANTLY and others in 1-12/24 hours";
        }
        setOrderMessage(values => ({...values, display: true, message: orderMessage}));
        let values = {
            area_code: areaCodes[areaCodeIndex],
            quantity: event.target.quantity.value,
            price: price,
            product: selectedProduct,
            user_id: user.id,
            endpoint: '/api/orders',
            token: user.api_token
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, message: response.data.message}));
                } else {
                    createUser(response.data.user).then(() => {
                        window.location.href = '/';
                    });
                    setError(values => ({...values, error: false, message: null}));
                    setSuccess(values => ({...values, success: true, message: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, message: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    if (user) {
        let productUser = selectedProduct ? user.products.filter((productUser) => productUser.product_id === selectedProduct.id) : [];
        return (
            <div>
                <div className='Card_Section'>
                    <div className='Accblncee_Card'>
                        <div style={{width: "75%"}}>
                            <p className='AcctBalancee'>Account Balance</p>
                            <p className='Pricee'>${user.balance}</p>
                        </div>
                        <div style={{width: "25%", textAlign: "right"}}>
                            <img src={cardicon} alt="cardicon" className='Card_Icon'/>
                        </div>
                    </div>
                    <div className='Note_Card'>
                        <p className='Note'>Note</p>
                        <p className='Note_Details'>Delivery Time: Area codes labeled "INSTANT" are 1-5 minute delivery.
                            Others are ~1-12/24 Hours.</p>
                    </div>
                </div>

                <p className='Selandord'>Select and Order Numbers</p>

                <div className="order-card-container row row-cols-4">
                    {productBody()}
                </div>
                {selectedProduct &&
                <div className='Main_Verizonphonenumber'>
                    <div style={{display: "flex"}}>
                        <div style={{width: "75%"}}>
                            <img src={selectedProduct.image_url} alt={selectedProduct.name} className='Verizon_Logo'/>
                            <p className='Main_Verizonphonenumber_Txt'>{selectedProduct.name} Port Numbers</p>
                            <p className='Main_Verizonphonenumber_Detail'>{selectedProduct.name} Numbers. You will
                                receive a port number, account number, and PIN.<br/>
                                Just specify an area code and press 'Order'. Please note the delivery time.
                                <br/>Please understand that layovers may be provided.
                                <br/>
                                <br/>
                                Numbers are valid for <span
                                    style={{color: '#007BFF', fontWeight: "600"}}>{selectedProduct.days} days.</span>
                                <br/>
                                {selectedProduct.online ?
                                    numberCount > 0 ?
                                        <span>
                                    INSTANT available number count is <span
                                            style={{color: '#007BFF', fontWeight: "600"}}>{numberCount} Numbers.</span>
                                </span>
                                        : <span>
                                    Numbers will be available in <span
                                            style={{color: '#007BFF', fontWeight: "600"}}>1-12/24 Hours.</span>
                                </span>
                                    : <span>
                                    Numbers are <span
                                        style={{color: '#cb6666', fontWeight: "600"}}>Temporary Unavailable.</span>
                                </span>
                                }
                            </p>
                        </div>
                        <div style={{width: "25%", textAlign: "right"}}>
                            <p className='Main_Verizonphonenumber_Number'>${productUser.length > 0 ? productUser[0].price : selectedProduct.price}
                                <br/>
                                <span style={{fontWeight: "400", fontSize: 20}}>/Number</span></p>
                        </div>
                    </div>
                    {orderMessage.display &&
                    <div className="alert alert-primary" role="alert">
                        {orderMessage.message}
                    </div>}
                    {success.success &&
                    <div className="alert alert-success" role="alert">
                        {success.message}
                    </div>}
                    {error.error &&
                    <div className="alert alert-danger" role="alert">
                        {error.message}
                    </div>}
                    <form onSubmit={formSubmit} className="product-form">
                        <div className='Dropdown_Box'>
                            <Select className='dropdowntxt'
                                    classNamePrefix="mySelect"
                                    placeholder="Select Area Code"
                                    name="areaCode" id="areaCode"
                                    options={areaCodes}
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => option['area_code'] + " : " + option['city'] + (option['numbers'].length > 0 ? " - INSTANT" : "")}
                                    required
                                    onChange={(event) => onAreaCodeChange(event)}
                            />
                        </div>
                        <div className="QuantityBox">
                            <input type="number" id="quantity" placeholder='Quantity' className='QuantityTxt' min="0"
                                   required/>
                        </div>
                        <button type="submit" className='blue-button Ordr_Btn order-now-button'>
                            {loading ?
                                <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                      aria-hidden="true"/>
                                : null
                            }
                            Order Now
                        </button>
                    </form>
                </div>
                }
            </div>
        );
    } else {
        return null;
    }
}

export default Neworder;
