import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import CSVReader from "react-csv-reader";
import CsvDownloader from 'react-csv-downloader';
import moment from "moment";
import Select from "react-select";

import {getUser} from "../auth/Auth";
import {loadNumbers, loadProducts} from "../common/Common";
import {deleteRequest, postRequest, uploadFile} from "../routes/Routes";

import Pagination from "../components/pagination/Pagination";

import Searchicon from "../assests/images/searchtxtboxicon.png";
import addImage from '../assests/images/add.png';

import '../assests/css/Orderhistory.css';
import '../assests/css/Resellermanager.css';

function ProductManager() {
    const [user, setUser] = useState(null);
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [success, setSuccess] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [edit, setEdit] = useState(false);
    const [image, setImage] = useState(null);
    const [numberSuccess, setNumberSuccess] = useState({});
    const [numberError, setNumberError] = useState({});
    const [numberLoading, setNumberLoading] = useState(false);
    const [numbers, setNumbers] = useState([]);
    const [filteredNumbers, setFilteredNumbers] = useState([]);
    const [uploadNumbers, setUploadNumbers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const imageInput = React.createRef();

    const csvHeaders = [{
        NUMBER: '',
        PIN: '',
        ACCOUNT: '',
    }];

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
        }).catch(() => null);
        loadProducts().then(data => {
            setProducts(data);
            setFilteredProducts(data);
        }).catch(() => null);
        loadNumbers().then(data => {
            setNumbers(data);
            setFilteredNumbers(data);
        }).catch(() => null);
    }, []);

    const productBody = () => {
        let body = [];
        filteredProducts.forEach((product, index) => {
            product.imageInput = React.createRef();
            body.push(
                <div className="col" key={index}>
                    <div
                        className={selectedProduct && selectedProduct.id === product.id ? 'order-card selected-order-card' : 'order-card'}
                        onClick={() => {
                            setSelectedProduct(product);
                            setNumberError({});
                            setNumberSuccess({});
                            setNumberLoading(false);
                            filterNumbers(product);
                        }}>
                        {product.online ?
                            product.numbers.length > 0 ?
                                <div className='Available_Box'>
                                    <p className='Available_Txt'>Available - INSTANT</p>
                                </div>
                                : <div className='Available_Box available-later-box'>
                                    <p className='Available_Txt available-later-txt'>Available - In 1-12/24 hours</p>
                                </div>
                            : <div className='Available_Box unavailable-box'>
                                <p className='Available_Txt unavailable-txt'>Temporary Unavailable</p>
                            </div>
                        }
                        <p className='Card_Name'>{product.name}</p>
                        <p className='Order_Numbers'>${product.price}/Number</p>
                        <button className='Manage_Btn' data-bs-toggle="modal" data-bs-target="#manageProductModal"
                                style={{width: '50%'}}
                                onClick={() => {
                                    setEdit(true);
                                    setSuccess({});
                                    setError({});
                                }}>
                            Manage
                        </button>
                    </div>
                </div>
            )
        });
        return body;
    }

    const filterNumbers = (product) => {
        let filteredNumbers = numbers.filter((number) => number.product_id === product.id);
        setFilteredNumbers(filteredNumbers);
    }

    const handleChange = (event, key) => {
        if (selectedProduct && edit) {
            if (key === 'image') {
                setImage(event.current.files[0]);
                setSelectedProduct(values => ({
                    ...values,
                    [key]: event.current.files[0]
                }));
            } else {
                setSelectedProduct(values => ({
                    ...values,
                    [key]: key === 'online' ? event.target.checked : event.target.value
                }));
            }
        } else {
            setImage(event.current.files[0]);
        }
    }

    const formSubmit = (event) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData();
        let endpoint;
        if (edit) {
            data.append('name', selectedProduct.name);
            data.append('price', selectedProduct.price);
            data.append('days', selectedProduct.days);
            data.append('online', selectedProduct.online === 1 ? 'true' : selectedProduct.online === 0 ? 'false' : String(selectedProduct.online));
            if (selectedProduct.image) {
                data.append('image', selectedProduct.image);
            }
            endpoint = '/api/products/update/' + selectedProduct.id;
        } else {
            data.append('name', event.target.name.value);
            data.append('price', event.target.price.value);
            data.append('days', event.target.days.value);
            data.append('online', 'true');
            if (imageInput.current.files.length > 0) {
                data.append('image', imageInput.current.files[0]);
            }
            endpoint = '/api/products';
        }
        uploadFile(data, endpoint, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, message: response.data.message}));
                } else {
                    if (edit) {
                        setProducts(values =>
                            values.map(product => {
                                if (product.id === selectedProduct.id) {
                                    return {
                                        ...response.data.product,
                                    };
                                }
                                return product;
                            }),
                        );
                        setFilteredProducts(values =>
                            values.map(product => {
                                if (product.id === selectedProduct.id) {
                                    return {
                                        ...response.data.product,
                                    };
                                }
                                return product;
                            }),
                        );
                    } else {
                        setProducts(values => [...values, {
                            ...response.data.product,
                        }]);
                        setFilteredProducts(values => [...values, {
                            ...response.data.product,
                        }]);
                    }
                    setError(values => ({...values, error: false, message: null}));
                    setSuccess(values => ({...values, success: true, message: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, message: "An error occurred!"}));
            }
            setEdit(false);
            setLoading(false);
        });
    }

    const deleteProduct = () => {
        deleteRequest('/api/products/' + selectedProduct.id, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    setProducts(values =>
                        values.filter(function (product) {
                            return product.id !== selectedProduct.id;
                        }),
                    );
                    setFilteredProducts(values =>
                        values.filter(function (product) {
                            return product.id !== selectedProduct.id;
                        }),
                    );
                    toast.success('Successfully deleted Product', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setEdit(false);
        });
    }

    const numberFormSubmit = (event) => {
        setNumberLoading(true);
        event.preventDefault();
        if (!selectedProduct) {
            setNumberError(values => ({...values, error: true, message: "Please select a product!"}));
            setNumberSuccess(values => ({...values, success: false, message: null}));
            setNumberLoading(false);
            return;
        }
        let values = {
            number: event.target.number.value,
            pin: event.target.pin.value,
            account: event.target.account.value,
            product_id: selectedProduct.id,
            endpoint: '/api/numbers',
            token: user.api_token
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setNumberError(values => ({...values, error: true, message: response.data.message}));
                } else {
                    setNumbers(values => [...values, {
                        ...response.data.number,
                    }]);
                    setFilteredNumbers(values => [...values, {
                        ...response.data.number,
                    }]);
                    setNumberError(values => ({...values, error: false, message: null}));
                    setNumberSuccess(values => ({...values, success: true, message: response.data.message}));
                }
            } else {
                setNumberError(values => ({...values, error: true, message: "An error occurred!"}));
            }
            setNumberLoading(false);
        });
    }

    const handleFileLoad = (data) => {
        let numbers = [];
        data.map(function (number) {
            let value = {
                number: number.number,
                pin: number.pin,
                account: number.account,
                product_id: selectedProduct ? selectedProduct.id : null,
            };
            numbers.push(value);
        });
        setUploadNumbers(numbers);
    };

    const uploadNumbersFunction = () => {
        setNumberLoading(true);
        if (!selectedProduct) {
            setNumberError(values => ({...values, error: true, message: "Please select a product!"}));
            setNumberSuccess(values => ({...values, success: false, message: null}));
            setNumberLoading(false);
            return;
        }
        uploadNumbers.forEach((uploadNumber) => {
            uploadNumber.product_id = selectedProduct.id
        });
        let values = {
            numbers: uploadNumbers,
            endpoint: '/api/numbers/upload',
            token: user.api_token
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setNumberError(values => ({...values, error: true, message: response.data.message}));
                } else {
                    setNumberError(values => ({...values, error: false, message: null}));
                    setNumberSuccess(values => ({...values, success: true, message: response.data.message}));
                    setUploadNumbers([]);
                    loadNumbers().then(data => {
                        setNumbers(data);
                        filterNumbers(selectedProduct);
                    }).catch(() => null);
                }
            } else {
                setNumberError(values => ({...values, error: true, message: "An error occurred!"}));
            }
            setNumberLoading(false);
        });
    }

    const filterProducts = (text) => {
        let filteredProducts;
        if (text === '') {
            filteredProducts = products;
        } else {
            text = text.toLowerCase();
            filteredProducts = products.filter((product) => `${product.name}`.toLowerCase().includes(text));
        }
        setFilteredProducts(filteredProducts);
    };

    const numberBody = (numbers) => {
        let body = [];
        numbers.forEach((number, index) => {
            body.push(
                <tr key={index}>
                    <td><span style={{
                        fontWeight: "400",
                        fontSize: 14,
                        color: "#007BFF",
                        lineHeight: "20px"
                    }}>{number.number}</span></td>
                    <td>{number.pin}</td>
                    <td>{number.account}</td>
                    <td className="tab-column">{number.area_code.area_code}</td>
                    <td className="tab-column">{number.product.name}</td>
                    <td className="desktop-column">{moment(new Date(number.created_at)).format('MMM Do YYYY, h:mm:ss a')}</td>
                </tr>
            )
        });
        return body;
    }

    const pageSize = 10,
        firstPageIndex = (currentPage - 1) * pageSize,
        lastPageIndex = firstPageIndex + pageSize,
        currentPageNumbers = filteredNumbers.slice(firstPageIndex, lastPageIndex);
    return (
        <div>
            <p className='ManMyUser'>Manage products</p>
            <div className="search-component">
                <div className="Search_Bbox">
                    <img src={Searchicon} alt="Searchicon" className='Search_Icon'/>
                    <input type="label" placeholder='Type to Search' className='Search_Txt'
                           onChange={(event) => filterProducts(event.target.value)}/>
                </div>
                <button className='blue-button Customexport_Btn' data-bs-toggle="modal"
                        data-bs-target="#createProductModal"
                        onClick={() => {
                            setEdit(false);
                            setSuccess({});
                            setError({});
                        }}>
                    Add New product
                </button>
            </div>
            <div className="order-card-container row row-cols-4" style={{marginTop: '2%'}}>
                {productBody()}
            </div>
            <div className='Main_Verizonphonenumber'>
                {numberSuccess.success &&
                <div className="alert alert-success" role="alert">
                    {numberSuccess.message}
                </div>}
                {numberError.error &&
                <div className="alert alert-danger" role="alert">
                    {numberError.message}
                </div>}
                <form onSubmit={numberFormSubmit} className="product-form add-product-form">
                    <div className="QuantityBox">
                        <input type="number" placeholder='Number' className='QuantityTxt' id="number" min="0" required/>
                    </div>
                    <div className="QuantityBox">
                        <input type="number" placeholder='Pin' className='QuantityTxt' id="pin" min="0" required/>
                    </div>
                    <div className="QuantityBox">
                        <input type="number" placeholder='Account' className='QuantityTxt' id="account" min="0"
                               required/>
                    </div>
                    <div className="Dropdown_Box">
                        <Select className='dropdowntxt'
                                classNamePrefix="mySelect"
                                placeholder="Select Product"
                                name="product" id="product"
                                options={products}
                                getOptionValue={option => option['id']}
                                getOptionLabel={option => option['name']}
                                value={selectedProduct}
                                required
                                onChange={(event) => setSelectedProduct(event)}
                        />
                    </div>
                    <button type='submit' className='blue-button Ordr_Btn'>
                        {numberLoading ?
                            <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                  aria-hidden="true"/>
                            : null
                        }
                        Add Number
                    </button>
                    <button type='button' className='blue-button Ordr_Btn' data-bs-toggle="modal"
                            data-bs-target="#uploadCSVModal" style={{marginLeft: '1%'}}>
                        Upload CSV
                    </button>
                </form>
            </div>
            <div>
                <table className='Tableee'>
                    <thead className='Theaddd'>
                    <tr>
                        <th>NUMBER</th>
                        <th>PIN</th>
                        <th>ACCOUNT</th>
                        <th className="tab-column">AREA CODE</th>
                        <th className="tab-column">PRODUCT</th>
                        <th className="desktop-column">DATE</th>
                    </tr>
                    </thead>
                    <tbody className='Tbodyyy'>
                    {numberBody(currentPageNumbers)}
                    </tbody>
                </table>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={filteredNumbers.length}
                    pageSize={pageSize}
                    siblingCount={0}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
            <div className="modal fade" id="createProductModal" tabIndex="-1" aria-labelledby="createProductModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="createProductModalLabel">Create New Product</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={formSubmit} className="portal-form">
                                <div className="form-group">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input className="form-control" type="text" placeholder='Name' id="name" required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="price" className="form-label">Price</label>
                                    <input className="form-control" type="number" placeholder='Minimum Price' id="price"
                                           required min="0" step=".01"/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="days" className="form-label">Valid Days</label>
                                    <input className="form-control" type="number" placeholder='Valid Days' id="days"
                                           required min="0"/>
                                </div>
                                {image &&
                                <img src={URL.createObjectURL(image)} style={{marginBottom: '5%'}}
                                     className="Verizon_Logo"/>
                                }
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="productImage"
                                           accept="image/*"
                                           ref={imageInput}
                                           onChange={(event) => handleChange(imageInput, 'image')}/>
                                    <label className="custom-file-label" htmlFor="productImage">
                                        <img src={addImage} alt="Add Icon"/>
                                        <div className="image-uploader-label">
                                            Drag & drop or select from your files
                                        </div>
                                    </label>
                                    <small id="image" className="form-text text-muted">
                                        Add Product Image
                                    </small>
                                </div>
                                {success.success &&
                                <div className="alert alert-success" role="alert">
                                    {success.message}
                                </div>}
                                {error.error &&
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>}
                                <button type='submit' className="btn blue-button form-button">
                                    {loading ?
                                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    Save
                                </button>
                                <button type='button' className="btn blue-border-button form-button"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setSuccess({});
                                            setError({});
                                        }}>
                                    Cancel
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="manageProductModal" tabIndex="-1" aria-labelledby="manageProductModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="manageProductModalLabel">Manage Product</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        {selectedProduct &&
                        <div className="modal-body">
                            <form onSubmit={formSubmit} className="portal-form">
                                <div className="form-group">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input className="form-control" type="text" placeholder='Name' id="name"
                                           required
                                           value={selectedProduct.name}
                                           onChange={(event) => handleChange(event, 'name')}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="price" className="form-label">Price</label>
                                    <input className="form-control" type="number" step=".01"
                                           placeholder='Minimum Price'
                                           id="price"
                                           required
                                           value={selectedProduct.price}
                                           onChange={(event) => handleChange(event, 'price')}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="days" className="form-label">Valid Days</label>
                                    <input className="form-control" type="number"
                                           placeholder='Valid Days'
                                           id="days"
                                           required
                                           value={selectedProduct.days}
                                           onChange={(event) => handleChange(event, 'days')}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="online" className="form-label">Online</label>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch"
                                               id="online" checked={selectedProduct.online}
                                               onChange={(event) => handleChange(event, 'online')}/>
                                    </div>
                                </div>
                                {image ?
                                    <img src={URL.createObjectURL(image)} style={{marginBottom: '5%'}}
                                         className="Verizon_Logo"/>
                                    : selectedProduct ?
                                        <img src={selectedProduct.image_url} style={{marginBottom: '5%'}}
                                             className="Verizon_Logo"/>
                                        : null
                                }
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="productImage"
                                           accept="image/*"
                                           ref={selectedProduct.imageInput}
                                           onChange={(event) => handleChange(selectedProduct.imageInput, 'image')}/>
                                    <label className="custom-file-label" htmlFor="productImage">
                                        <img src={addImage} alt="Add Icon"/>
                                        <div className="image-uploader-label">
                                            Drag & drop or select from your files
                                        </div>
                                    </label>
                                    <small id="image" className="form-text text-muted">
                                        Add Product Image
                                    </small>
                                </div>
                                {success.success &&
                                <div className="alert alert-success" role="alert">
                                    {success.message}
                                </div>}
                                {error.error &&
                                <div className="alert alert-danger" role="alert">
                                    {error.message}
                                </div>}
                                <button type='submit' className="btn blue-button form-button">
                                    {loading ?
                                        <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                              aria-hidden="true"/>
                                        : null
                                    }
                                    Save
                                </button>
                                <button className="btn red-button form-button" data-bs-dismiss="modal"
                                        onClick={() => deleteProduct()}>
                                    Delete Product
                                </button>
                                <button type='button' className="btn blue-border-button form-button"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                            setSuccess({});
                                            setError({});
                                        }}>
                                    Cancel
                                </button>
                            </form>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div className="modal fade" id="uploadCSVModal" tabIndex="-1" aria-labelledby="uploadCSVModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="uploadCSVModalLabel">Upload CSV</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                        </div>
                        <div className="model-body" style={{padding: '5%'}}>
                            <CSVReader
                                onFileLoaded={handleFileLoad}
                                parserOptions={{
                                    header: true,
                                    skipEmptyLines: true,
                                    transformHeader: header => header.toLowerCase()
                                }}
                            />
                            <Select className='dropdowntxt'
                                    classNamePrefix="mySelect"
                                    placeholder="Select Product"
                                    name="product" id="product"
                                    options={products}
                                    getOptionValue={option => option['id']}
                                    getOptionLabel={option => option['name']}
                                    value={selectedProduct}
                                    required
                                    onChange={(event) => setSelectedProduct(event)}
                            />
                            <button type='button' className="btn blue-button form-button" style={{marginTop: '5%'}}
                                    data-bs-dismiss="modal"
                                    onClick={() => uploadNumbersFunction()}>
                                Upload CSV
                            </button>
                            <CsvDownloader filename="csv-template" datas={csvHeaders}>
                                <button type='button' className="btn blue-border-button form-button">
                                    Download Template CSV
                                </button>
                            </CsvDownloader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductManager;
